import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import DecorationTransparent1Svg from './../../../svg/decoration-transparent-1'
import { ReactSlick as CarouselSlider } from './../../../../hooks/drupal/parseContentProcessor/Carousel/useReactSlick'

const translation = {
    soft4net_hero_text: {
        pl: [
            { h1: `Strony internetowe`, strong: `technologie - seo - grafika`, },
            { h1: `Sklepy internetowe`, strong: `marketing - consulting - outsourcing`, },
            { h1: `System rezerwacji`, strong: `zarządzanie - sem - analiza`, },
            { h1: `Aplikacje dedykowane`, strong: `projekty - multimedia - social media`, },
        ],
        en: [
            { h1: `Websites`, strong: `technologies - seo - graphics`, },
            { h1: `E-commerce solutions`, strong: `marketing - consulting - outsourcing`, },
            { h1: `Booking engine`, strong: `management - sem - analyze`, },
            { h1: `Dedicated applications`, strong: `projects - multimedia - social media`, },
        ],
    },
}

const ComponentStateless = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    return (
        <React.Fragment>
            <div className="is-cropped enable-carousel hero arrow-inside dots-inside">
                <CarouselSlider slidesToShow={1} slidesToScroll={1} dots={true} infinite={true} arrows={false} fade={true}>
                    {translation.soft4net_hero_text[intl.locale].map(({ h1, strong, bg }, index) => 
                        <React.Fragment>
                            <section className={`hero-area slide-${index}`}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="text-white font-tertiary">{h1}</h1>
                                            <strong>{strong}</strong>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </React.Fragment>
                    )}
                </CarouselSlider>
                <div className="layer-bg w-100">
                    {/* <img className="img-fluid w-100" src={ ImagesIllustrationsLeafBg } alt="bg-shape" /> */}
                    <DecorationTransparent1Svg />
                </div>
            </div>
        </React.Fragment>
    )
}

export default ComponentStateless