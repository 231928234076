import React from "react"
// import { Link } from "gatsby" // we use wrapper one from plugin = gatsby-plugin-intl
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { graphql } from "gatsby"

// s4n custom code
import FragmentPageDetails from './../model/fragments/nodePage/page-details'
import { useIntl } from "gatsby-plugin-intl"
import { useParseInlineImages } from '../hooks/drupal/parseInlineImages'
// import DrupalModuleParagraphsContainer from './../components/drupal/module/paragraphs/container'
// import Widgets from "./../components/widgets"
// import Team from './../components/s4n/Team/index-static';
// import loadable from "@loadable/component"
// import ImagesIllustrationsLeafBg from './../assets/images/illustrations/leaf-bg.png';
// import Portfolio from './../components/s4n/Portfolio'
// import Partners from './../components/s4n/Partners'
// import Testimonials from './../components/s4n/Testimonials'

// Subpages
// import kebabCase from "lodash.kebabcase"
// import Link from "./../components/s4n/Link"
// import Page from "./../model/node/page"
import Hero from './../components/s4n/ProjectSpecific/Hero'
import useReplacementNode from './../hooks/drupal/parseContentProcessor/useReplacement/Node'

// import ImageUtils from './../utils/gatsby/image'
// import ImageS4n from './../components/s4n/image'

// const PAGE_2_IMAGE = [
//   `custom/page/about-us.jpg`,     // 24
//   `custom/page/methodology.jpg`,  // 15
//   `custom/page/price-list.jpg`,   // 23
// ];


const IndexPage = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { data } = props;

    // console.log(data)

    // const node = data.allNodePage.edges[0].node;
    // const { node } = data.allNodePage.edges.filter(({ node }) => intl.locale === node.langcode)[0];
    let nodeInLanguage = data.allNodePage.edges.filter(({ node }) => intl.locale === node.langcode); // just for netlify!!!
    nodeInLanguage = !!nodeInLanguage.length ? nodeInLanguage[0].node : null;
    
    let nodeBodyField = null;
    // let nodeParagraphField = null;
    const oReplacement = useReplacementNode(nodeInLanguage);

    // if (nodeInLanguage) {
      // prepare/extract data
      // let nodeBodyField = node.body ? node.body.value : null;
      nodeBodyField = useParseInlineImages(nodeInLanguage, true, oReplacement); // You can use the react-html-parser in case you don't want to use dangerouslySetInnerHTML attribute
      // nodeParagraphField = nodeInLanguage.relationships ? nodeInLanguage.relationships.field_paragraph : null
      // let nodeParagraphField = useParseBodyInlineImages(nodeInLanguage.relationships.field_paragraph)
    // }

    return (
      <Layout node={nodeInLanguage}>

        <SEO 
          nodeId={nodeInLanguage.drupal_internal__nid} 
          // title={nodeInLanguage.title} 
          title={t({ id: `siteMetadata_title_home_page` })} 
          field_metatag={nodeInLanguage.field_metatag}
        />

        <Hero />

        {/* <ul className="list-unstyled ml-5 mt-3 position-relative zindex-1">
          <li className="mb-3"><a className="text-white" href="#"><i className="ti-facebook"></i></a></li>
          <li className="mb-3"><a className="text-white" href="#"><i className="ti-instagram"></i></a></li>
          <li className="mb-3"><a className="text-white" href="#"><i className="ti-dribbble"></i></a></li>
          <li className="mb-3"><a className="text-white" href="#"><i className="ti-twitter"></i></a></li>
        </ul> */}

        {/* <h1>Hi people!</h1> */}
        {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
          <Image />
        </div> */}
        {/* <Link to="/page-2/">Go to page 2</Link> */}
        {/* { t({ id: "title" }) } */}
        {/* <p>{ nodeInLanguage.title }</p> */}
        {/* <div dangerouslySetInnerHTML={{ __html: nodeInLanguage.body ? nodeInLanguage.body.value : '' }}></div> */}

        {nodeBodyField}

        {/* {[
          24,  // O nas
          15, // Jak dzia≈Çamy
          23, // Cennik
        ].map((nid, index) => {
            const nodePage = Page.getItemByNodeId(nid, intl.locale);

            if (!nodePage) {
              return null;
            }

            const relativePath = PAGE_2_IMAGE[index];
            const img = ImageUtils.getGatsbyImageDataByRelativePath(relativePath);            
            const styleCover = {
              'background-position': `50% 50%`,
              'background-image': `url('${img.src}')`,
              'background-repeat': `no-repeat`,
            }

            return (
                <section className={`position-relative pb-5 bg-light ${kebabCase(nodePage.title)}`}>
                    <div className="about-bg-img d-none d-lg-block d-md-block" style={styleCover}>
                      <ImageS4n src={PAGE_2_IMAGE[index]} alt={nodePage.title} _className="img-fluid rounded w-100" _maxWidth="300px" _maxHeight="5rem" />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-sm-12 col-md-8">
                                <div className="about-content">
                                    <h5 className="subtitle"><Link to={nid}></Link></h5>
                                    {nodePage && nodePage.field_summary && <p dangerouslySetInnerHTML={{ __html: nodePage.field_summary }}></p>}
                                    <p><Link to={nid} className="btn btn-secondary goto-scroll" role="button">{ t({ id: "soft4net_more" }) }</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        })} */}

        {/* <Team /> */}

        {/* <Subpages 
          nid={14} 
          template={`service`}
        /> */}

        {/* <Partners /> */}

        {/* <Testimonials /> */}

        {/* <Team /> */}

        {/* @see: https://github.com/gatsbyjs/gatsby/issues/8787 */}
        {/* { '/pl/' === props.location.pathname && <Widgets />} */}
    
        {/* <DrupalModuleParagraphsContainer>
          { nodeParagraphField }
        </DrupalModuleParagraphsContainer> */}
    
        {/* {node.edges.map(({ node }) => {
          return (
            <>
              <h3><Link to={ node.path.alias }>{ node.title }</Link></h3>
              <small><em>{ node.created }</em></small>
              <div dangerouslySetInnerHTML={{ __html: node.body ? node.body.value.split(' ').splice(0, 50).join(' ') + '...' : '' }}></div>
              <div dangerouslySetInnerHTML={{ __html: node.body ? node.body.value : '' }}></div>
            </>
          )
        })} */}
    
      </Layout>
    )
}

export default IndexPage

export const query = graphql`
  query {
    allNodePage (
        filter: {
          drupal_internal__nid: {
            eq: 1
          }
          # langcode: {
          #   eq: "pl"
          # }
        }
      ) {
        edges {
          node {
            ...FragmentPageDetails
          }
        }
    }
  }
`